import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ContactEn from "./lang/contact.en.json";
import ContactFr from "./lang/contact.fr.json";
import ContactIt from "./lang/contact.it.json";
import CookiesEn from "./lang/cookies.en.json";
import CookiesFr from "./lang/cookies.fr.json";
import CookiesIt from "./lang/cookies.it.json";
import CraeteQREn from "./lang/createqr.en.json";
import CraeteQRFr from "./lang/createqr.fr.json";
import CraeteQRIt from "./lang/createqr.it.json";
import DashboardEn from "./lang/dashboard.en.json";
import DashboardFr from "./lang/dashboard.fr.json";
import DashboardIt from "./lang/dashboard.it.json";
import CommonEn from "./lang/en.json";
import CommonFr from "./lang/fr.json";
import CommonIt from "./lang/it.json";
import PricesEn from "./lang/prices.en.json";
import PricesFr from "./lang/prices.fr.json";
import PricesIt from "./lang/prices.it.json";
import PrivacyEn from "./lang/privacy.en.json";
import PrivacyFr from "./lang/privacy.fr.json";
import PrivacyIt from "./lang/privacy.it.json";
import RefundEn from "./lang/refund.en.json";
import RefundFr from "./lang/refund.fr.json";
import RefundIt from "./lang/refund.it.json";
import TermsEn from "./lang/terms.en.json";
import TermsFr from "./lang/terms.fr.json";
import TermsIt from "./lang/terms.it.json";
import UnsubscribeEn from "./lang/unsubscribe.en.json";
import UnsubscribeFr from "./lang/unsubscribe.fr.json";
import UnsubscribeIt from "./lang/unsubscribe.it.json";
const en = {
    ...CommonEn,
    ...CraeteQREn,
    ...PricesEn,
    ...ContactEn,
    ...UnsubscribeEn,
    ...DashboardEn,
    ...PrivacyEn,
    ...CookiesEn,
    ...TermsEn,
    ...RefundEn,
};
const fr = {
    ...CommonFr,
    ...CraeteQRFr,
    ...PricesFr,
    ...ContactFr,
    ...UnsubscribeFr,
    ...DashboardFr,
    ...PrivacyFr,
    ...CookiesFr,
    ...TermsFr,
    ...RefundFr,
};
const it = {
    ...CommonIt,
    ...CraeteQRIt,
    ...PricesIt,
    ...ContactIt,
    ...UnsubscribeIt,
    ...DashboardIt,
    ...PrivacyIt,
    ...CookiesIt,
    ...TermsIt,
    ...RefundIt,
};
const getLanguage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accepted = [
        "en",
        "fr",
        // "it",
    ];
    const lang = urlParams.get("lang") ||
        localStorage.getItem("lng") ||
        window.navigator.language.split("-")[0];
    return accepted.includes(lang) ? lang : "en";
};
i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
        // it: {
        //   translation: it,
        // },
    },
    lng: getLanguage(),
});
export default i18n;
