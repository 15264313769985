import { jsx as _jsx } from "react/jsx-runtime";
import { Button, styled } from "@mui/material";
const StyledButton = styled(Button)(({ variant }) => ({
    textTransform: "none",
    borderRadius: "8px",
    margin: "0 0 10px",
    padding: "10px 30px",
    ...(variant === "contained" && {
        boxShadow: "inset 0px 0px 0px 2px rgba(0,0,0,0.2), 0px 2px 4px rgba(0,0,0,0.1)",
        "&:hover": {
            boxShadow: "inset 0px 0px 0px 2px rgba(0,0,0,0.2), 0px 2px 4px rgba(0,0,0,0.1)",
        },
    }),
}));
const ScannerButton = (props) => {
    var _a, _b;
    return (_jsx(StyledButton, { ...props, size: (_a = props.size) !== null && _a !== void 0 ? _a : "large", variant: (_b = props.variant) !== null && _b !== void 0 ? _b : "contained", children: props.children }));
};
export default ScannerButton;
