var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import theme from "./Theme";
import "./i18n";
import Home from "./pages/Home";
import Unsubscribe from "./pages/Unsubscribe";
const recaptchaSiteKey = (_a = process.env.GOOGLE_RECAPTCHA_SITE_KEY_TOPQR) !== null && _a !== void 0 ? _a : "";
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
        },
    },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(Box, { sx: { maxWidth: "1400px", margin: "auto" }, children: _jsx(BrowserRouter, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(ThemeProvider, { theme: theme, children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(Home, { recaptchaSiteKey: recaptchaSiteKey }) }), _jsx(Route, { path: "/unsubscribe", element: _jsx(Unsubscribe, { recaptchaSiteKey: recaptchaSiteKey }) })] }) }) }) }) }) }));
