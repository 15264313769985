import { jsx as _jsx } from "react/jsx-runtime";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from "react";
import { Link as RouterLink, } from "react-router-dom";
const LinkBehavior = React.forwardRef((props, ref) => {
    const { href, ...other } = props;
    // Map href (MUI) -> to (react-router)
    return (_jsx(RouterLink, { "data-testid": "custom-link", ref: ref, to: href, ...other }));
});
LinkBehavior.displayName = "LinkBehavior";
const theme = createTheme({
    palette: {
        background: {
            paper: "#F9FAFB",
        },
        primary: {
            main: "#2F54EB",
        },
    },
    status: {
        defaultBG: "#F9FAFB",
    },
    typography: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        // body1: {
        //   color: "#636B75",
        //   margin: "10px 0",
        //   fontWeight: "500",
        //   fontSize: "1.4rem",
        //   "@media (min-width:600px)": {
        //     margin: "20px 0",
        //   },
        // },
        body2: {
            color: "#636B75",
            fontWeight: "400",
            fontSize: "1rem",
        },
        h1: {
            fontSize: "3rem",
            fontWeight: "600",
        },
        h2: {
            fontSize: "2.4rem",
            fontWeight: "500",
        },
        h3: {
            fontSize: "1.6rem",
            color: "#636B75",
        },
        h4: {
            fontSize: "1.2rem",
        },
        subtitle1: {
            fontSize: "1rem",
            color: "#636B75",
        },
        subtitle2: {
            fontSize: "1rem",
            color: "#636B75",
        },
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: LinkBehavior,
            },
        },
        MuiButtonBase: {
            defaultProps: {
                LinkComponent: LinkBehavior,
            },
        },
        MuiInputBase: {
            defaultProps: {
                style: {
                    backgroundColor: "white",
                },
            },
        },
    },
});
export default responsiveFontSizes(theme);
