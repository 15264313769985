import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
const StyledLoadingButton = styled(LoadingButton)(() => ({
    textTransform: "none",
    borderRadius: "10px",
    margin: "20px 0",
    padding: "10px 30px",
    width: "100%",
}));
export default function ScannerLoadingButton(props) {
    var _a, _b;
    return (_jsx(StyledLoadingButton, { ...props, size: (_a = props.size) !== null && _a !== void 0 ? _a : "large", variant: (_b = props.variant) !== null && _b !== void 0 ? _b : "contained", children: _jsx("span", { children: props.children }) }));
}
