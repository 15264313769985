import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, FormControl, NativeSelect, } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CompanyImg from "../../../img/scanner/company-img.png";
export default function Footer() {
    const { t, i18n } = useTranslation();
    const handleChangeLng = (event) => {
        const lng = event.target.value;
        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
    };
    const location = useLocation();
    const isDev = new URLSearchParams(location.search).get("dev") === "true";
    return (_jsxs(Box, { sx: { marginTop: "8rem", px: 2, py: 2, backgroundColor: "#F9FAFB" }, children: [_jsx(Box, { sx: { width: "50%" } }), _jsx(Divider, {}), _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", children: [_jsx(Box, { component: "img", sx: {
                            height: "auto",
                            width: "100%",
                            maxWidth: "230px",
                            mt: "20px",
                        }, src: CompanyImg }), _jsx(FormControl, { children: _jsxs(NativeSelect, { value: i18n.language, onChange: handleChangeLng, children: [_jsx("option", { value: "en", children: "English" }), _jsx("option", { value: "fr", children: "French" })] }) })] })] }));
}
