import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import logoPath from "../../../img/scanner/logo.svg";
const Header = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    return (_jsxs(Stack, { sx: {
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#F9FAFB",
        }, children: [_jsxs(Stack, { direction: "row", alignItems: "center", sx: {
                    pointerEvents: pathname.includes("create") ? "none" : "all",
                }, children: [_jsx(Link, { href: "/", children: _jsx(Box, { component: "img", sx: { width: "50px" }, alt: "Topqr Logo", src: logoPath }) }), _jsx(Link, { href: "/", sx: { textDecoration: "none", color: "black" }, children: _jsx(Typography, { variant: "h2", component: "p", sx: {
                                display: {
                                    xs: "block",
                                    fontWeight: "normal",
                                },
                            }, children: "TopQR" }) })] }), pathname === "/" && (_jsx(Link, { href: "/unsubscribe", underline: "none", children: _jsx(Typography, { color: "#000", variant: "h6", children: t("unsubscribe") }) })), pathname === "/unsubscribe" && (_jsx(Link, { href: "/", underline: "none", children: _jsx(Typography, { color: "#000", variant: "h6", children: t("contact-us") }) }))] }));
};
export default Header;
