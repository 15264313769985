import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "@emotion/styled";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { Component, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import unsubscribePath from "../../../img/scanner/unsubscribe/unsubscribe.svg";
import Theme from "../Theme";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScannerButton from "../components/common/ScannerButton";
const StyledTextField = styled(TextValidator)({
    width: "100%",
});
class Unsubscribe extends Component {
    constructor(props) {
        super(props);
        this.theme = Theme;
        this.handleChange = (event) => {
            const { formData } = this.state;
            formData[event.target.name] = event.target.value;
            this.setState({ formData });
        };
        this.handleSubmit = async () => {
            this.state.formData["g-recaptcha-response"] =
                await this.recaptchaRef.current.executeAsync();
            const options = {
                url: "/api/unsubscribe",
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: this.state,
            };
            axios
                .request(options)
                .then((result) => {
                this.setState({
                    unsubscribed: result.data.unsubscribed,
                });
            })
                .catch((error) => this.setState({ error: error.response.data.error }));
        };
        this.myRef = createRef();
        this.recaptchaRef = createRef();
        this.state = {
            formData: {
                email: "",
                "g-recaptcha-response": "",
            },
            unsubscribed: false,
            error: null,
        };
    }
    render() {
        const { t, recaptchaSiteKey } = this.props;
        const { formData, unsubscribed, error } = this.state;
        return (_jsxs(_Fragment, { children: [_jsx(Header, {}), unsubscribed ? (_jsx(Stack, { sx: {
                        marginTop: "10%",
                        alignItems: "center",
                        height: "20vw",
                    }, children: _jsxs(Stack, { sx: {
                            alignItems: "center",
                            padding: "20px",
                            maxWidth: "1061px",
                            border: "1px solid",
                            borderColor: this.theme.palette.success.light,
                            borderRadius: "20px",
                        }, children: [_jsx(CheckCircleOutline, { sx: {
                                    fontSize: "2.5rem",
                                    color: this.theme.palette.success.light,
                                } }), _jsx(Typography, { sx: { color: this.theme.palette.success.light }, children: t("unsubscribe-success") })] }) })) : (_jsxs(Stack, { sx: {
                        marginTop: "10%",
                        padding: "0 5%",
                        flexFlow: "row-reverse wrap",
                        alignItems: "center",
                        rowGap: "50px",
                    }, children: [_jsxs(Box, { sx: { flex: "1 1 400px" }, children: [_jsx(Typography, { variant: "h1", children: t("unsubscribe-title-one-click") }), _jsx(Typography, { sx: { textAlign: "justify" }, children: t("unsubscribe-title-one-click-desc") }), _jsxs(ValidatorForm, { ref: this.myRef, onSubmit: this.handleSubmit, children: [_jsxs(Stack, { mt: 2, pb: "2rem", spacing: 2, sx: { justifyContent: "space-around", height: "100%" }, children: [_jsx(StyledTextField, { label: t("email"), onChange: this.handleChange, name: "email", value: formData.email, validators: ["required", "isEmail"], errorMessages: [
                                                        t("createqr-unsubscribe-required-field"),
                                                        t("createqr-unsubscribe-invalid-email"),
                                                    ], helperText: t(error), error: error !== null }), _jsx(ScannerButton, { type: "submit", sx: { width: "100%" }, variant: "contained", disabled: unsubscribed, children: (unsubscribed && t("unsubscribed")) ||
                                                        (!unsubscribed && t("unsubscribe-now")) })] }), _jsx(ReCAPTCHA, { ref: this.recaptchaRef, sitekey: recaptchaSiteKey, size: "invisible" })] })] }), _jsx(Box, { sx: {
                                textAlign: "center",
                                flex: "1 0 50%",
                            }, children: _jsx(Box, { sx: { maxWidth: "90%" }, component: "img", alt: "topqr unsubscribe image", src: unsubscribePath }) })] })), _jsx(Footer, {})] }));
    }
}
export default withTranslation()(Unsubscribe);
