import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, FormHelperText, Stack, Typography, styled } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { createRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import contactPath from "../../../img/scanner/contact/contact.svg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScannerLoadingButton from "../components/common/ScannerLoadingButton";
const StyledTextField = styled(TextFieldElement)({
    width: "100%",
    ".MuiInputBase-root": {
        backgroundColor: "#fff",
    },
});
const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.status.defaultBG,
    borderRadius: "8px",
    padding: "3%",
}));
const schema = z.object({
    firstname: z.string().min(1, "required"),
    lastname: z.string().min(1, "required"),
    email: z.string().email("required"),
    message: z.string().min(1, "required"),
});
const Contact = ({ recaptchaSiteKey }) => {
    const { t, i18n } = useTranslation();
    const [error, setError] = useState("");
    const recaptchaRef = createRef();
    const mutation = useMutation({
        mutationFn: async (variables) => {
            var _a;
            const recaptcha = await ((_a = recaptchaRef.current) === null || _a === void 0 ? void 0 : _a.executeAsync());
            return axios.post("/api/contact", {
                formData: {
                    firstname: variables.firstname,
                    lastname: variables.lastname,
                    email: variables.email,
                    message: variables.message,
                    "g-recaptcha-response": recaptcha,
                },
                language: i18n.language,
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        },
        onError(error) {
            var _a;
            if (error instanceof AxiosError) {
                setError((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message);
            }
        },
    });
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsxs(Stack, { sx: {
                    marginTop: "10%",
                    padding: "0 5%",
                    flexFlow: "row-reverse wrap",
                    alignItems: "center",
                    rowGap: "50px",
                }, children: [_jsxs(StyledBox, { sx: { flex: "1 1 550px" }, children: [_jsx(Typography, { variant: "h1", children: t("contact-us-title") }), _jsx(Typography, { sx: { textAlign: "justify" }, children: t("contact-us-desc") }), mutation.isSuccess ? (_jsx(Box, { sx: { width: "100%" }, children: _jsx(Typography, { sx: { width: "100%", color: "success.main" }, children: t("contact-us-sent") }) })) : (_jsxs(FormContainer, { resolver: zodResolver(schema), defaultValues: {
                                    firstname: "",
                                    lastname: "",
                                    email: "",
                                    message: "",
                                }, onSuccess: (data) => mutation.mutate(data), children: [_jsxs(Stack, { mt: 2, pb: "2rem", spacing: 2, sx: { justifyContent: "space-around", height: "100%" }, children: [_jsx(StyledTextField, { size: "small", name: "firstname", label: t("firstname") }), _jsx(StyledTextField, { size: "small", name: "lastname", label: t("lastname") }), _jsx(StyledTextField, { size: "small", name: "email", label: t("email") }), _jsx(StyledTextField, { size: "small", name: "message", label: t("message"), multiline: true, rows: 6 }), _jsx(FormHelperText, { error: error !== null, children: error }), _jsx(ScannerLoadingButton, { type: "submit", sx: {
                                                    borderRadius: "10px",
                                                    fontSize: "1.1rem",
                                                }, loading: mutation.isLoading, children: t("contact-us-send") })] }), _jsx(ReCAPTCHA, { ref: recaptchaRef, sitekey: recaptchaSiteKey, size: "invisible" })] }))] }), _jsx(Box, { sx: {
                            textAlign: "center",
                            flex: "1 0 33%",
                        }, children: _jsx(Box, { component: "img", alt: "topqr contact image", src: contactPath, sx: { maxWidth: "100%" } }) })] }), _jsx(Footer, {})] }));
};
export default Contact;
